import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  // remove # from URL
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
    },
    {
      path: '/chat-room/:username',
      name: 'chatRoom',
      component: () => import(/* webpackChunkName: "detail" */ './views/Detail.vue'),
      props: true
    }
  ],
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
})

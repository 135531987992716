import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import VueObserveVisibility from 'vue-observe-visibility'
import VueLazyload from 'vue-lazyload'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(VueObserveVisibility)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: 'img/loading.gif',
  attempt: 1
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app" class="container-fluid">
    <router-view/>
  </div>
</template>

<script>

export default {
  // https://vue-meta.nuxtjs.org/
  metaInfo: {
    titleTemplate: 'Hot Tranny Tube | %s',
    htmlAttrs: {
      lang: 'cs',
      amp: false
    },
    meta: [
      { name: 'application-name', content: 'Hot Tranny Tube' }
    ]
  }
}
</script>

<style>

</style>
